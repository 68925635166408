import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
    <h1>No se encontró</h1>
    <p>La página que buscas no existe. Lo sentimos.</p>
    </section>
  </Layout>
)

export default NotFoundPage
